article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36rem;
}

.article-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
    height: 8rem;
    padding: 0 1rem;
    padding: 1rem 2rem 0 2rem;
}

.article-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    width: 100%;
    height: 20rem;
    padding: 2rem 4rem;
    gap: 1.5rem;
}

.article-content > p.title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3f65;
    height: fit-content;
    max-height: 6rem;
}

.article-content > p.description {
    font-size: 2rem;
    font-weight: 400;
    color: #2c3f65;
    height: fit-content;
    height: 12rem;
}

.article-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    width: 100%;
    height: 8rem;
    padding: 0 2rem 1rem 2rem;
}

.icon-set {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1.25rem 2rem;
    gap: 1rem;
    font-size: 1.5rem;
}

.icon-set > svg {
    width: 2rem;
    height: 2rem;
    fill: #2c3f65 !important;
}