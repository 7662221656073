#navigator {
  /* width: calc(100% - 40px); */
  width: var(--header-width);
  height: var(--header-height);
  margin: 2rem auto;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger-btn {
  display: none;
}

#hamburger-content {
  display: none;
  width: var(--header-width);
  height: calc(var(--content-height) + var(--footer-height));
  transform: translateY(var(--header-height));
  margin: 0;
  padding: 2.5rem;
  opacity: 0;
  transition: opacity 0.75s ease-in-out;
}

@media screen and (max-width: 959px) {
	/* 959px以下に適用されるCSS（タブレット用） */
}

@media screen and (max-width: 640px) {
	/* 640px以下に適用されるCSS（スマホ用） */
  #navigator {
    width: var(--header-width);
    height: var(--header-height);
    z-index: 150;
  }

  #navigator > .logo-content {
    width: 7.0rem;
    height: 7.0rem;
    border-radius: 1.5rem;
  }

  #navigator > .logo-content > a {
    width: 7.0rem;
    height: 7.0rem;
  }

  #navigator > .logo-content > a > .logo {
    width: 7.0rem;
    height: 7.0rem;
    padding: 0.5rem;
  }

  #navigator > .logo-content > a > .logo > img {
    width: 5.75rem;
    height: 5.75rem;
    margin: auto;
  }
  
  #navigator > .logo-content .logo-name {
    display: none;
  }

  #navigator > .navigation-list {
    display: none;
  }

  .hamburger-btn {
    display: block;
    width: 7.0rem;
    height: 7.0rem;
  }

  .openbtn {
    position: relative;/*ボタン内側の基点となるためrelativeを指定*/
    cursor: pointer;
    width: 7.0rem;
    height: 7.0rem;
    border-radius: 0.75rem;
  }

  .hamburger-btn.active .openbtn {
    transform: translateX(0.05rem);
  }
  
  /*ボタン内側*/
  .openbtn span {
    display: inline-block;
    transition: all .4s;/*アニメーションの設定*/
    position: absolute;
    left: 1.5rem;
    height: 0.4rem;
    border-radius: 0.75rem;
    background: #2c3f65;
  }
  
  .openbtn span:nth-of-type(1) {
    top: 2.0rem; 
    width: 25%;
    left: 3.5rem;
  }
  
  .openbtn span:nth-of-type(2) {
    top: 3.25rem;
    width: 45%;
    left: 1.75rem;
  }
  
  .openbtn span:nth-of-type(3) {
    top: 4.5rem;
    width: 25%;
  }
  
  /*activeクラスが付与されると線が回転して×になる*/
  
  .hamburger-btn.active .openbtn span:nth-of-type(1) {
    top: 2.0rem; 
    width: 25%;
    left: 3.5rem;
    transform: rotate(-45deg) translateY(0.02rem) translateX(-1.05rem);
  }
  
  .hamburger-btn.active .openbtn span:nth-of-type(2) {
    width: 40.5%;
    transform: rotate(45deg) translateY(-0.175rem);
  }
  
  .hamburger-btn.active .openbtn span:nth-of-type(3) {
    top: 4.5rem;
    width: 25%;
    transform: translateY(-1.0rem) translateX(0.5rem) rotate(-45deg);
  }

  #navigator-wrapper {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2.0rem;
    transition: transform 1.25s ease-in-out, width 1.5s ease-in-out, height 1.25s ease-in-out, border-radius 1.25s ease-in, box-shadow 1.25s ease-out;
    z-index: 100;
    
    position: fixed;
    width: var(--header-width);
    height: var(--header-height);
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: #E0E5EC;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #navigator-wrapper.fullscreen {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
    transform: translateY(-2.0rem);
    border-radius: 0;
    
    height: 100vh;
    width: 100vw;
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  #hamburger-content.active {
    display: block;
    margin: 3.0rem 2.0rem;
    opacity: 1;
  }

  #hamburger-content > .navigation-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    gap: 3rem;
  }
  
  #hamburger-content > .navigation-list > .navigation-item {
    /* width: auto; */
  }
  
  #hamburger-content > .navigation-list > .navigation-item > .neumorphism-btn {
    width: 30.0rem !important;
    height: 8.0rem !important;
    font-size: 2.5rem !important;
    border-radius: 3.0rem !important;
  }

  #hamburger-content > .navigation-list > .navigation-item > .neumorphism-btn > label {
    border-radius: 3.0rem !important;
  }
}