#navigator > .navigation-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 35rem;
  height: 8rem;
  gap: 1rem;
}

#navigator > .navigation-list > .navigation-item {
  width: 10rem !important;
}

#navigator > .navigation-list > .navigation-item.neumorphism-btn {
  width: fit-content;
  height: 5rem !important;
  font-size: 1.5rem !important;
}