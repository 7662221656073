section.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: var(--content-height);
}

section.login .login-form h2 {
    font-size: 2.5rem;
}

section.login .login-form {
    font-size: 2.0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5.0rem;
    padding: 0 5.0rem;
    position: relative;
    padding: 8.0rem 10.0rem;
    height: var(--content-height);
    width: var(--content-width);
}

section.login .login-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5.0rem;
    padding-bottom: 2rem;
    height: 75%;
}

section.login .login-form .txt_field {
    height: 7.0rem;
    width: 100%;
    padding: 1.5rem 3.0rem;
    display: flex;
}

section.login .login-form .neumorphism-btn {
    height: 7.0rem;
    width: 100%;
}

input:-internal-autofill-selected,
input:-webkit-autofill {
    box-shadow: 0 0 0px 999px #E0E5EC inset;
    background-color: initial !important;
}